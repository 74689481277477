<template>
	<div class="call-filter">
		<button
			id="page-filter-toggle"
			class="call-filter-toggle btn btn-outline-dark"
			aria-haspopup="listbox"
			aria-label="Pages filter"
			@click.prevent.stop="onToggleClick"
		>
			<div class="btn-ripple"></div>
			<span v-if="hasFilter" class="btn-label">
				Filters ({{ selected }})
			</span>
			<span v-else class="btn-label">
				Filters
			</span>

			<font-awesome-icon
				class="btn-icon"
				:icon="['fal', show ? 'chevron-up' : 'chevron-down']"
			/>
		</button>
		<transition name="fade">
			<div v-if="show" class="call-filter-dropdown" :class="{ show }">
				<div
					id="call-filter-list"
					tabindex="-1"
					role="listbox"
					aria-label="Choose a call filter"
					aria-multiselectable="true"
					class="call-filter-dropdown-list"
					@click.stop
				>
					<div class="call-filter-toggle-all">
						<button
							v-if="selected < totalOptions"
							type="button"
							class="btn btn-dark"
							@click="selectAll"
						>
							<div class="btn-ripple"></div>
							<span class="btn-label">Select All</span>
						</button>
						<button
							v-else
							type="button"
							class="btn btn-dark"
							@click="deselectAll"
						>
							<div class="btn-ripple"></div>
							<span class="btn-label">Deselect All</span>
						</button>
					</div>

					<div class="call-filter-header">
						Filters
					</div>
					<div
						id="call-filter-item-complete"
						class="call-filter-item"
						role="option"
					>
						<input
							id="call-filter-complete"
							v-model="filters"
							type="checkbox"
							value="complete"
							class="call-filter-option-checkbox"
						/>

						<label
							class="call-filter-option-label"
							for="call-filter-complete"
						>
							Complete
						</label>
					</div>

					<div
						id="call-filter-item-incomplete"
						class="call-filter-item"
						role="option"
					>
						<input
							id="call-filter-incomplete"
							v-model="filters"
							type="checkbox"
							value="incomplete"
							class="call-filter-option-checkbox"
						/>

						<label
							class="call-filter-option-label"
							for="call-filter-incomplete"
						>
							New
						</label>
					</div>
					<div class="call-filter-submit">
						<button
							type="button"
							class="btn btn-outline-warning w-100"
							@click.prevent.stop="onApplyFiltersClick"
						>
							<div class="btn-ripple"></div>
							<span class="btn-label">Apply Filters</span>
						</button>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'

/**
 * The escape numeric key code.
 *
 * @type {Number}
 */
const ESCAPE_KEY_CODE = 27

/**
 * The total number of statuses + urgent/non-urgent filters.
 *
 * @type {Number}
 */
const TOTAL_FILTERS = 2

export default {
	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Number of selected items on dropdown.
		 *
		 * @return {Number}
		 */
		selected() {
			return this.filters.length
		},

		/**
		 * Total number of options.
		 *
		 * @return {Number}
		 */
		totalOptions() {
			return TOTAL_FILTERS
		},

		...mapGetters('pages', ['hasFilter']),

		...mapState({
			activeFilters: state => state.pages.filters.statuses,
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Add the escape keydown event listeners.
		 *
		 * @return {void}
		 */
		addEventListeners() {
			document.addEventListener('click', this.closeDropdown)
			document.addEventListener('keydown', this.onEscapeKeyDown)
		},

		/**
		 * Handle a global click event outside of the dropdown toggler.
		 *
		 * @return {void}
		 */
		closeDropdown() {
			if (this.show) {
				this.show = false

				setTimeout(() => this.removeEventListeners(), 0)
			}
		},

		/**
		 * Remove all selected filters and types.
		 *
		 * @return {void}
		 */
		deselectAll() {
			this.filters = []
		},

		/**
		 * Get the all of the filters as a store payload object.
		 *
		 * @return {Object}
		 */
		getFiltersAsObject() {
			return {
				statuses: this.filters,
			}
		},

		/**
		 * Determine if the given keycode is the escape key.
		 *
		 * @param {Number} keyCode
		 * @return {Boolean}
		 */
		isEscapeKeyCode(keyCode) {
			return keyCode === ESCAPE_KEY_CODE
		},

		/**
		 * Apply the filters and load the pages.
		 *
		 * @return {void}
		 */
		async onApplyFiltersClick() {
			const payload = {
				fromFilters: true,
				callId: this.$route.params.sid,
			}
			this.setFilters({ ...this.getFiltersAsObject(), payload })

			this.show = false
		},

		/**
		 * Handle the escape keydown event.
		 *
		 * @returns {void}
		 */
		onEscapeKeyDown(event) {
			if (this.show && this.isEscapeKeyCode(event.keyCode)) {
				this.show = false

				this.removeEventListeners()
			}
		},

		/**
		 * Handle the dropdown click event.
		 *
		 * @return {void}
		 */
		onToggleClick() {
			this.show = !this.show

			setTimeout(() => {
				return this.show
					? this.addEventListeners()
					: this.removeEventListeners()
			}, 0)
		},

		/**
		 * Mark all types and filters as selected.
		 *
		 * @return {void}
		 */
		selectAll() {
			this.filters = ['complete', 'incomplete']
		},

		/**
		 * Remove the global event listeners.
		 *
		 * @return {void}
		 */
		removeEventListeners() {
			document.removeEventListener('keydown', this.onEscapeKeyDown)
			document.removeEventListener('click', this.closeDropdown)
		},

		...mapActions('pages', {
			setFilters: 'setFilters',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'PagesFilterDropdown',

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Sync the local selected filters with the store's filter state.
		 *
		 * @return {void}
		 */
		activeFilters() {
			this.filters = this.activeFilters
		},

		/**
		 * Sync the local types & filters with the store when toggled.
		 *
		 * @return {void}
		 */
		show() {
			this.filters = this.activeFilters
		},
	},

	/**
	 * The component's before destroy lifecycle hook.
	 *
	 * @return {void}
	 */
	beforeDestroy() {
		this.removeEventListeners()
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			filters: [],
			show: false,
		}
	},
}
</script>
