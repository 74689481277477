<template>
	<div class="inbox-header with-filters sticky top-0 z-50">
		<div class="inbox-title">
			<span class="inbox-title-label">
				{{ partner ? partner.name : 'Pages' }}
			</span>
			<router-link
				:to="{ name: 'app.paging.create' }"
				class="btn btn-sm"
				data-tooltip="New Page"
				data-position="left"
			>
				<div class="btn-ripple"></div>
				<span class="sr-only">New Page</span>
				<font-awesome-icon
					class="btn-icon"
					:icon="['far', 'plus']"
					aria-hidden="true"
				/>
			</router-link>
		</div>
		<div class="call-filters">
			<pages-filter-dropdown />
			<provider-filter-dropdown is-pages-filter />
		</div>
		<div class="chip-set px-4">
			<chip v-for="item in filters" :key="item" condensed>
				{{ item | toFilterName }}
				<template #trailing>
					<font-awesome-icon
						class="chip-remove"
						:icon="['fal', 'times']"
						@click.prevent.stop="onRemoveFilter(item)"
					/>
				</template>
			</chip>
		</div>
		<div class="p-2">
			<div class="flex flex-row">
				<div :key="activePartnerId" class="w-full flex relative">
					<button
						type="button"
						class="dashboard-filter-input-icon calls-page-date-filter-icon"
						@click.prevent="onDateFilterClick"
					>
						<font-awesome-icon :icon="['fal', 'calendar-alt']" />
					</button>
					<flat-pickr
						id="dateRange"
						ref="datePicker"
						:key="omdDateRange"
						v-model="dateFilter"
						:config="configs.range"
						type="text"
						name="dateRange"
						class="form-input dashboard-filter-input calls-page-date-filter m-0"
						:placeholder="`Last ${omdDateRange || 90} days`"
					/>
					<button
						v-if="dateFilter"
						type="button"
						class="btn btn-sm dashboard-filter-btn calls-page-date-filter-btn"
						data-position="left"
						@click.prevent="onRemoveDateFilter"
					>
						<font-awesome-icon
							class="btn-icon"
							:icon="['fal', 'times']"
						/>
					</button>
				</div>
			</div>
		</div>
		<div class="flex items-center justify-between">
			<button
				type="button"
				aria-label="Sort dates button"
				class="flex items-center justify-center"
				style="padding-left: 10px"
				@click.prevent="$emit('sort')"
			>
				<font-awesome-icon class="btn-icon" :icon="['fad', sortIcon]" />
			</button>
			<div class="inbox-header-subtitle" style="padding-right: 10px">
				{{ count || 0 }} / {{ total }} records
			</div>
		</div>
	</div>
</template>
<script>
import Chip from '@/components/Chip'
import { mapActions, mapGetters, mapState } from 'vuex'
import PagesFilterDropdown from './PagesFilterDropdown'
import moment from 'moment'
import 'flatpickr/dist/flatpickr.css'
import FlatPickr from 'vue-flatpickr-component'
import ProviderFilterDropdown from './ProviderFilterDropdown'

const API_FORMAT = 'ddd, DD MMM YYYY HH:mm:ss [GMT]'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		Chip,
		PagesFilterDropdown,
		ProviderFilterDropdown,
		FlatPickr,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the sort icon based on the current sort direction.
		 *
		 * @return {String}
		 */
		sortIcon() {
			if (this.sort === 'asc') {
				return 'sort-amount-down'
			}

			return 'sort-amount-up'
		},

		/**
		 * Get the total number of models available (or total in the store).
		 *
		 * @return {Number}
		 */
		total() {
			if (this.count > this.meta.total) {
				return this.count || 0
			}

			return this.meta.total || 0
		},

		/**
		 * Constructs and returns the payload for fetching call data.
		 *
		 * @returns {Object}
		 */
		getCallsFetchingPayload() {
			const payload = {
				fromFilters: true,
				callId: this.$route.params.sid,
			}
			return payload
		},

		...mapGetters({
			count: 'pages/count',
			meta: 'pages/meta',
			hasFilter: 'pages/hasFilter',
			partner: 'partner',
		}),

		...mapState({
			filters: state => state.pages.filters.statuses,
			omdDateRange: state => state.auth.user.omd_date_range,
			activePartnerId: state => state.partners.active,
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		onDateFilterClick() {
			const flatPicker = this.$refs.datePicker.fp

			if (!flatPicker.isOpen) {
				flatPicker.open()
				flatPicker.close()
				return flatPicker.open()
			}

			flatPicker.close()
		},
		/**
		 * Remove a filter and refresh the filtered pages.
		 *
		 * @return {void}
		 */
		async onRemoveFilter(filter) {
			await this.removeFilter(filter)
			if (this.hasFilter) {
				await this.get(this.getCallsFetchingPayload)
			}
		},

		async onRemoveDateFilter() {
			this.range = []
			this.dateFilter = null
			const flatPicker = this.$refs.datePicker.fp
			flatPicker.open()

			try {
				await this.setLoading(true)
				await this.removeDateRangeFilter()
				if (flatPicker && flatPicker.isOpen) {
					flatPicker.close()
				}
				await this.getCallTypesCount()
				await this.get(this.getCallsFetchingPayload)
			} catch (e) {
				await this.setLoading(false)
			} finally {
				await this.setLoading(false)
			}
		},

		...mapActions('pages', {
			get: 'get',
			removeFilter: 'removeFilter',
			setLoading: 'setLoading',
			removeDateRangeFilter: 'removeDateRangeFilter',
			setFilters: 'setFilters',
		}),

		...mapActions({ getCallTypesCount: 'callTypes/get' }),

		/**
		 * Handle the date range change event.
		 *
		 * @return {void}
		 */
		async onDateRangeChange(range) {
			if (range.length !== 2) {
				return
			}

			this.range = range
			const start = moment(range[0]).format('YYYY-MM-DD')
			const end = moment(range[1]).format('YYYY-MM-DD')

			const startDate = moment(start)
				.startOf('day') // Set time to 00:00:00
				.utc()
				.format(API_FORMAT)

			const endDate = moment(end)
				.endOf('day') // Set time to 23:59:59
				.utc()
				.format(API_FORMAT)

			try {
				await this.setLoading(true)
				await this.setFilters({
					starts: startDate,
					ends: endDate,
				})

				// await this.getCallTypesCount()
				await this.get(this.getCallsFetchingPayload)
			} catch (e) {
				await this.setLoading(false)
			} finally {
				await this.setLoading(false)
			}
		},

		async onDateChange(selectedDates, dateStr, instance) {
			const next90Days = moment(selectedDates[0]).add(
				this.omdDateRange - 1 || 89,
				'days'
			)
			instance.config.maxDate = moment.min(next90Days, moment()).toDate()
			instance.config.minDate = moment(selectedDates[0]).toDate()
			instance?.selectedDateElem?.focus()
			this.onDateRangeChange(selectedDates)
		},

		async onClose(selectedDates, dateStr, instance) {
			if (instance) {
				instance.config.maxDate = moment().toDate()
				instance.config.minDate = undefined
			}
			if (selectedDates && selectedDates.length === 1) {
				instance.config.maxDate = moment().toDate()
			}
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'InboxPagesHeader',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The current sort direction.
		 *
		 * @type {String}
		 */
		sort: {
			type: String,
			default: 'asc',
		},
	},

	watch: {
		activePartnerId: {
			handler(newValue, oldValue) {
				if (newValue && oldValue && newValue !== oldValue) {
					this.range = []
					this.dateFilter = null
				}
			},
			immediate: true,
		},
	},

	beforeDestroy() {
		this.range = []
		this.dateFilter = null
		this.removeDateRangeFilter()
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		const today = new Date().setDate(new Date().getDate())
		return {
			configs: {
				range: {
					allowInput: false,
					altInput: true,
					altFormat: 'm/d/Y',
					dateFormat: 'n/j/Y',
					mode: 'range',
					onChange: this.onDateChange,
					onClose: this.onClose,
					wrap: true,
					maxDate: today,
				},
			},
			dateFilter: null,
			isDateFilterOpen: false,
			removingFilter: false,
			range: [],
		}
	},
}
</script>
